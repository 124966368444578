import React from 'react'
import { useQuery } from '@apollo/client'
import Layout from '../components/layout'
import ProjectsPageHeader from '../components/projects-page-header'
import ProjectPageProjectGallery from '../components/projects-page-project-gallery'
import ProjectsPageProjects from '../components/projects-page-projects'
import FullscreenLoadingSpinner from '../components/fullscreen-loading-spinner'
import { GET_PROJECTS_PAGE_DATA } from '../queries/queries'

const ProjectPage = () => {

    const { data, loading, error } = useQuery(GET_PROJECTS_PAGE_DATA)

    const projectGalleryMapper = () => {
        return data.projects.map(project => {
            return (
                <ProjectPageProjectGallery 
                    key={project.id}
                    slug={project.project_link_slug}
                    projectName={project.project_name}
                    projectGallery={project.project_gallery}
                />
            )
        })
    }

    return (
        <>
            <FullscreenLoadingSpinner loading={loading} />
            {!loading &&
            <Layout>
                {/* <ProjectsPageHeader 
                    pageHeaderText={data.projectsPage.page_heading}
                    pageHeaderImage={data.projectsPage.page_header_image.url}
                /> */}
                <ProjectsPageProjects projectData={data.projects} />
                {projectGalleryMapper()}
            </Layout>}
        </>
    )
}

export default ProjectPage