import React from 'react'
import { Link } from 'gatsby'

import useWindowDimensions from '../hooks/window-dimensions'

const ProjectsPageProjects = ({ projectData }) => {

    const windowDimensions = useWindowDimensions()

    const projectMapper = () => {
        return projectData.map(project => {
            return (
              <Link
                key={project.id}
                to={`/projects#${project.project_link_slug}`}
              >
                <div className="bg-white overflow-hidden mr-6 mb-6 flex flex-col items-center">
                  <div
                    id="hero-carousel"
                    className="bg-cover bg-no-repeat bg-center opacity-100 hover:opacity-75 transition duration-150"
                    style={{
                      backgroundImage: `url(${process.env.GATSBY_BACKEND_URL}${project.project_cover_image.url})`,
                      height: `${
                        windowDimensions.width >= 570 ? "450px" : "400px"
                      }`,
                      width: `${
                        windowDimensions.width >= 570 ? "256px" : "236px"
                      }`,
                    }}
                  ></div>
                  <p className="text-center text-lg font-garamond">
                    {project.project_name}
                  </p>
                </div>
              </Link>
            )
        })
    }

    return (
        <section className="bg-white">
            <div className="container mx-auto px-6 pb-20 pt-36 flex flex-col flex-wrap sm:flex-row justify-center">
                {projectMapper()}
            </div>
        </section>
    )
}

export default ProjectsPageProjects